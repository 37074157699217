import css from 'styled-jsx/css';
import { phone_tablet } from '../../../styles/media';

export const styles = css`
    .Slider {
      position: relative;
    }
    .Slider__viewport {
      position: relative;
      overflow: hidden;
      display: flex;
      flex-wrap: nowrap;
    }

    @media ${phone_tablet} {
      .Slider__viewport {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
      }
    }
  `;
