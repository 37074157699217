import animateScrollTo from 'animated-scroll-to';

export function swipeContainer(container, scrollValue, duration = 350) {
  animateScrollTo(scrollValue, {
    element: container,
    horizontal: true,
    cancelOnUserAction: true,
    maxDuration: duration,
    minDuration: duration,
  });
}
