/**
 * @todo goes to the 1st slide when clicked 'right' on the last slide
 * and to the last slide when clicked 'left' on the first slide
 * */

const getTargetSlideIndex = (slidesLength: number, nextPosition: number)  => {
  const toTheFirstSlide = nextPosition >= slidesLength;
  const toTheIntermediateSlide = nextPosition < slidesLength && nextPosition >= 0;

  const slideIndex = toTheIntermediateSlide
    ? nextPosition
    : toTheFirstSlide
      ? 0
      : slidesLength -1;

  return slideIndex;

};

export default getTargetSlideIndex;
