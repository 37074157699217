import Responsive from '../../Responsive/Responsive';
import { INavButtonsProps } from '../types';
import { styles } from './NavButtons.styles';

const NavButtons = (props: INavButtonsProps) => {
  const { controls: { onPrevSlide, onNextSlide, sliderState }} = props;
  const { isEndPositionLeft, isEndPositionRight } = sliderState;

  return (
    <Responsive tabletDesktop>
      <div className='NavButtons__infoButtons'>
        <button
          type='button'
          className='icon-arrow-small-left NavButtons__infoButton NavButtons__infoButton_prev'
          data-marker='Previous slide'
          data-testid='slider_Button_prev'
          onClick={onPrevSlide}
          disabled={isEndPositionLeft}
        />
        <button
          type='button'
          className='icon-arrow-small-right NavButtons__infoButton NavButtons__infoButton_next'
          data-marker='Next slide'
          data-testid='slider_Button_next'
          onClick={onNextSlide}
          disabled={isEndPositionRight}
        />
      </div>

      <style jsx>{styles}</style>
    </Responsive>
  );
};

export default NavButtons;
