import getDataStatus from '../../../../utils/system/getDataStatus';
import { getIsVisible } from './getIsVisible';

export function setSlidesDataStatus(
  container: HTMLElement,
  inaccuracy = 0.5,
): void {
  const slides: HTMLCollection = container.children;

  Array.prototype.forEach.call(slides, (slide: HTMLElement, index): void => {
    const isActive = getIsVisible(container, index, inaccuracy);

    slide.dataset.status = getDataStatus(isActive);
    slide.classList.add(`slide-${getDataStatus(isActive)}`);
  });
}
