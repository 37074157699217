import { IScrollSliderParams } from '../types';
import { getNextPosition } from './getNextPosition';
import { getPrevPosition } from './getPrevPosition';
import { getScrolledSlidesCount } from './getScrolledSlidesCount';
import getTargetSlideIndex from './getTargetSlideIndex';
import { swipeContainer } from './scrollUtils';

export function scrollSlider(
  container: HTMLElement,
  {
    fullWidthScroll = false,
    callback = () => {},
    slidesPerScroll = 1,
    scrollDirection,
    position = null,
    duration = 350,
  }: IScrollSliderParams,
): void {
  if (!container) return;

  const { scrollLeft, clientWidth, children } = container;
  const slide = children[0] as HTMLElement | null | undefined;

  if (!slide) return;

  const slideWidth = slide.offsetWidth;
  const args = [
    scrollLeft,
    slideWidth,
    fullWidthScroll
      ? getScrolledSlidesCount(clientWidth, slideWidth)
      : slidesPerScroll,
  ];
  let nextPosition: number = position;

  if (position === null) {
    if (scrollDirection === 'right')
      nextPosition = getNextPosition(...args);

    if (scrollDirection === 'left')
      nextPosition = getPrevPosition(...args);
  }

  const targetSlide =
    children[getTargetSlideIndex(children.length, nextPosition)] as HTMLElement | null | undefined;

  const nextScrollValue = targetSlide.offsetLeft;
  const currentPosition = getScrolledSlidesCount(nextScrollValue, slideWidth);

  swipeContainer(container, nextScrollValue, duration);

  callback(currentPosition);
}
