import { FunctionComponent } from 'react';
import { styles } from './Slider.styles';
import { IProps } from './types';

const Slider: FunctionComponent<IProps> = (props) => {
  const {
    sliderRef,
    children,
    controls,
    customNavigation,
  } = props;

  return (
    <div
      className='Slider'
      onMouseOver={controls?.handleMouseOver}
      onMouseOut={controls?.handleMouseOut}
    >
      <div
        className='Slider__viewport'
        data-testid='slider_viewport'
        ref={sliderRef}
        onTouchStart={controls?.handleTouchStart}
        onTouchEnd={controls?.handleTouchEnd}
        onTouchMove={controls?.handleTouchMove}
        onScroll={controls?.handleScrollViewport}
      >
        {children}
      </div>
      {customNavigation && customNavigation}
      <style jsx>{styles}</style>
    </div>
  );
};

export default Slider;
