import css from 'styled-jsx/css';
import {
  not_lg,
  not_md,
  not_sm,
  not_desktop,
  not_tablet_md,
  not_tablet,
  not_phone,
  not_tablet_desktop,
  not_phone_tablet,
  not_lg_md,
} from '../../../styles/media';

export default css`
@media ${not_lg} {
  .Responsive_lg {
    display: none;
  }
}
@media ${not_md} {
  .Responsive_md {
    display: none;
  }
}
@media ${not_lg_md} {
  .Responsive_lgMd {
    display: none;
  }
}
@media ${not_sm} {
  .Responsive_sm {
    display: none;
  }
}
@media ${not_desktop} {
  .Responsive_desktop {
    display: none;
  }
}
@media ${not_tablet_md} {
  .Responsive_tabletMd {
    display: none;
  }
}
@media ${not_tablet} {
  .Responsive_tablet {
    display: none;
  }
}
@media ${not_phone} {
  .Responsive_phone {
    display: none;
  }
}
@media ${not_tablet_desktop} {
  .Responsive_tabletDesktop {
    display: none;
  }
}
@media ${not_phone_tablet} {
  .Responsive_phoneTablet {
    display: none;
  }
}
@media ${not_phone_tablet} {
  .Responsive_phonePortrait {
    display: none;
  }
}
`;
